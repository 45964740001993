<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <h2 class="main_ttl"><span>月例会</span></h2>
        <div class="contents" v-html="fetch_data.lead_text"></div>

        <div class="cts_box">
          <h3 class="sub_ttl"><span>スケジュール</span></h3>
          <template v-if="fetch_data.monthly_lives.length === 0">
            現在募集中のイベントはありません。しばらくお待ち下さい。
          </template>
          <ul class="event_list" v-else>
            <li class="flex" v-for="model in fetch_data.monthly_lives" :key="model.id">
              <div class="info">
                <div class="class">
                  <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                  <span class="tag class02" v-if="model.rank_regular">本科</span>
                  <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                  <span class="tag class04" v-if="model.rank_general">一般</span>
                  <span class="tag class05" v-if="model.rank_student">学生科</span>
                  <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
                </div>
                <h3>{{ model.title }}</h3>
                <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span class="time">{{ model.open_time }}</span></div>
                <p v-html="model.list_text"></p>
                <div class="detail flex">
                  <template v-if="model.held_type === 'held_type_temporary'">
                    <span class="tag">開催地</span>{{ model.relay_place }}
                  </template>
                  <template v-else>
                    <span class="tag">中継会場</span>{{ model.relay_place }}
                  </template>
                </div>
              </div>
              <div class="btn">
                <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }" class="linkBtn light">詳細・申し込み</router-link>
              </div>
            </li>

          </ul>
          <!--pager-->
          <div class="pager flex" v-if="false">
            <div class="btn prev"><a href=""></a></div>
            <div class="btn"><a href="">1</a></div>
            <div class="btn current">2</div>
            <div class="btn"><a href="">3</a></div>
            <div class="btn dotted"></div>
            <div class="btn"><a href="">10</a></div>
            <div class="btn next"><a href=""></a></div>
          </div>
          <!--//pager-->
        </div>
        <!--非塾生には表示されない？-->
        <div class="cts_box" v-if="false">
          <div class="btn_wrap">
            <p class="event_caution">
              終了した月例会を確認するには<br class="sp">こちらのボタンを押してください。<br>
              （参加した研修会の履歴を確認できます）
            </p>
            <router-link :to="{ name: 'UsersCustomerJoinEvents'}" class="submit basic arrow">研修会参加履歴</router-link>
          </div>
        </div>
        <!--//非塾生には表示されない？-->

        <div class="cts_box" v-if="fetch_data.closed_monthly_lives.length > 0">
          <h3 class="sub_ttl"><span>開催実績</span></h3>
          <ul class="event_list">
            <li class="flex" v-for="model in fetch_data.closed_monthly_lives" :key="model.id">
              <div class="info">
                <div class="class">
                  <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                  <span class="tag class02" v-if="model.rank_regular">本科</span>
                  <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                  <span class="tag class04" v-if="model.rank_general">一般</span>
                  <span class="tag class05" v-if="model.rank_student">学生科</span>
                  <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
                </div>
                <h3>{{ model.title }}</h3>
                <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span class="time">{{ model.open_time }}</span></div>
                <p v-html="model.list_text"></p>
                <div class="detail flex">
                  <template v-if="model.held_type === 'held_type_temporary'">
                    <span class="tag">開催地</span>{{ model.relay_place }}
                  </template>
                  <template v-else>
                    <span class="tag">中継会場</span>{{ model.relay_place }}
                  </template>
                </div>
              </div>
              <div class="btn">
                <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }" class="linkBtn light skeleton">詳細</router-link>
              </div>
            </li>

          </ul>
        </div>


      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {
  },
  data() {
    return {
      fetch_data: null,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei.json`, {
            params: {
              draft: this.$route.query.draft,
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
